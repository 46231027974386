<!-- 2022年度无人机飞行架次 -->
<template>
  <div class="main">
    <div class="header">
      <div class="card card-right">
        <img class="" src="@/assets/newImage/fly-count.png" alt="" />
        <div class="right">
          <span class="text">飞行次数</span>
          <span class="num">{{ totalCount }}</span>
        </div>
      </div>
      <div class="card card-left">
        <span>单位：次</span>
      </div>
    </div>
    <div id="uavjc"></div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
export default {
  data() {
    return {
      names: [],
      shuju: [],
      totalCount: 0,
    };
  },
  async mounted() {
    await this.abc();
    this.getEcharts();
  },
  methods: {
    async abc() {
      await API.zjc().then((res) => {
        console.log(res, "年度飞行总价次");
        this.totalCount = 0;
        res.forEach((item) => {
          this.names.push(String(item.orgName).replace('联飞', '').replace('中航智', ''));
          this.shuju.push(item.flightSorties);
          this.totalCount += parseInt(item.flightSorties);
        });
      });
      console.log(this.shuju,'是什么呢');
    },
    // 图表
    getEcharts() {
      // 颜色
      let color1 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#1A6ABF" },
          { offset: 0.5, color: "#34A7C3" },
          { offset: 1, color: "#57D0BA" },
        ],
        false
      );
      let color2 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000000FF" },
          { offset: 0.5, color: "#608731FF" },
          { offset: 1, color: "#F2FDAAFF" },
        ],
        false
      );
      let color3 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000000FF" },
          { offset: 0.5, color: "#2E8F87FF" },
          { offset: 1, color: "#B7FFF9FF" },
        ],
        false
      );
      let color4 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000218FF" },
          { offset: 0.5, color: "#4C4EBCFF" },
          { offset: 1, color: "#BEBCFFFF" },
        ],
        false
      );
      let color5 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#1B0225FF" },
          { offset: 0.5, color: "#743ACCFF" },
          { offset: 1, color: "#CFA6FFFF" },
        ],
        false
      );
      const myChart = this.$echarts.init(document.getElementById("uavjc"));
      let option = {
        tooltip: {
          trigger: "axis",
          extraCssText:'width:100px;height:40px;background:transparent;',
          axisPointer: {
            // Use axis to trigger tooltip
            // type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
          formatter: (params) => {
            // console.log(params, 'params');
            let res  = `
            <div style="
              background-color: #295183; 
              height: 60px; 
              width: 80px;
              border: 0;
              border-top: 4px solid #3C7CC2;
              padding:0;
              margin: 0;
              padding-left: 20%;
              display: flex;
              justify-content: center;
              flex-direction: column;
            ">
                  <div style="font-size: 22px; color: #fff">${params[0].data}</div>
                  <div style="font-size: 12px; color: #6884AC; margin-top: 5px">${params[0].name}</div>
            </div>
                `;
            return res;
           }
        },
        legend: {
          show: "false",
          top: "15",
          left: "left",
          itemWidth: 0, // 图例图形的宽度
          itemHeight: 0, // 图例图形的高度
          textStyle: {
            color: "#AAB7C3",
          },
          formatter: function (name) {
            let data = option.series[0].data;
            let tarValue = 0;
            for (let i = 0; i < data.length; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value;
              }
            }
            return name

          },
        },
        grid: {
          top: '10%',
          left: '11%',
          right: '1%',
          bottom: "20%",
        },
        xAxis: {
          show: true,
          type: "category",
          data:this.names,
          axisLabel: {
            color: "#AAB7C3",
            fontSize: "13",
             interval: 0,
          },
          axisLine: {
            lineStyle: {
              type: "solid", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
              
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#AAB7C3", //Y轴内容文字颜色
              fontSize: "12",
            },
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color:['#1A283F'],
            },
          },
        },
        series: [
          {
            data: this.shuju,
            // name: "单位 :  次",
            type: "bar",
            backgroundStyle: {
              //   color: [color1, color2, color3, color4, color5],
            },
            label:{
              normal: {
              show:false,
              position:'top'
              },
              formatter:"{@value}"
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [color1, color1, color1, color1, color1];
                  return colorList[params.dataIndex];
                },
                barBorderRadius: [6, 6, 6, 6],
              },
            },
            barWidth: 8,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
}

.header {
  width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 0 6px;

  .card-right {
    width: 30%;
    // background-color: aquamarine;
    display: flex;
    margin-left: 5px;

    .right {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text {
        font-size: 12px;
        color: #c0c0c0;
      }

      .num {
        font-size: 16px;
        color: #fff;
        font-weight: 600;
      }
    }


  }
  .card-left{
    width: 70%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    color: #59677E;
    font-size: 14px;
  }
}
#uavjc {
  width: 100%;
  height: 80%;
}
</style>