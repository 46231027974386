<!-- 飞行详情 -->
<template>
  <div class="xianqing XfontOne">
    <div class="jz jz1">
      <img class="" src="@/assets/newImage/jz-alltask.png" alt="" />
      <div class="right">
        <span class="text">总任务</span>
        <span class="num">{{ renwu }}</span>
      </div>
    </div>
    <div class="jz jz2">
      <img class="" src="@/assets/newImage/jz-mileage.png" alt="" />
      <div class="right">
        <span class="text">飞行里程</span>
        <span class="num">{{ licheng }}</span>
      </div>
    </div>
    <div class="jz jz3">
      <img class="" src="@/assets/newImage/jz-FlightFrequency.png" alt="" />
      <div class="right">
        <span class="text">飞行次数</span>
        <span class="num">{{ cishu }}</span>
      </div>
    </div>
    <div class="jz jz4">
      <img class="" src="@/assets/newImage/jz-usercount.png" alt="" />
      <div class="right">
        <span class="text">总人数</span>
        <span class="num">{{ useCount }}</span>
      </div>
    </div>
    <div class="jz jz5">
      <img class="" src="@/assets/newImage/jz-flightduration.png" alt="" />
      <div class="right">
        <span class="text">飞行时长</span>
        <span class="num">{{ shichang }}</span>
      </div>
    </div>
    <div class="jz jz6">
      <img class="" src="@/assets/newImage/jz-alldevice.png" alt="" />
      <div class="right">
        <span class="text">总设备</span>
        <span class="num">{{totalDevices}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
import bus from "@/utils/bus.js";
export default {
  data () {
    return {
      cishu: '',
      licheng: '',
      shichang: '',
      renwu: '',
      totalDevices: 0,
      useCount: 0,
    };
  },
  mounted () {
    this.abc();
    // 设备总数
    bus.$on('totalCount', (v) => { 

      this.totalDevices = v;
    });

    bus.$on('useCount', (v) => { 

      this.useCount = v;
    });



  },
  methods: {
    abc () {
      API.zongshuju().then((res) => {
        console.log(res, "总数据");
        //  获取过来的值为字符串,但是会带小数点,所以先转成数字型,再用parseInt()去掉小数点,因为split只接受字符型,所以重新转回字符型
        // 总次数
        let a = parseInt(Number(res[0].data));
        this.cishu = a;
        // 总里程
        let b = parseInt(Number(res[1].data));
        let n = parseInt(Number(b));
        this.licheng = b;
        // 总时长
        let c = parseInt(Number(res[2].data));
        this.shichang = c;
        // 总任务
        let d = parseInt(Number(res[3].data));
        this.renwu = d;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.xianqing {
  // width: 1888px;
  width: 100%;
  // height: 105px;
  // background-image: url("~@/assets/newImage/bosstiaokuang.png");
  background-size: 100% 100%;
  // margin: 22px 0 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  

  .jl13 {
    margin: 0 10px 0 13px;
  }
}

.XfontOne {
  // font-size: 24px;
  // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  // font-weight: bold;
  // color: #ffffff;
  // text-shadow: 0px 0px 6px rgba(0, 187, 255, 0.7);
}

.fontTwo {
  font-size: 42px;
  font-family: UniDreamLED;
  color: #69f3ff;
}

ul {
  display: flex;
  list-style: none;
  padding: 5px 0 5px 0;

  li {
    width: 30px;
    background: #000000;
    border-radius: 4px;
    text-align: center;
    line-height: 5vh;
    margin: 0 4px 0 0;
  }
}

.jz {
  display: flex;
  white-space: nowrap;
  height: 100%;
  width: 10%;
  justify-content: center;
  align-items: center;
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 5px;

    .text {
      font-size: 12px;
      color: #c0c0c0;
    }

    .num {
      font-size: 18px;
      color: #fff;
      margin-top: 3px;
    }
  }
}

.jz1 {
  .img {
    background-image: url('../../../assets/newImage/jz-alltask.png');
  }
}
.jz2 {
  .img {
    background-image: url('../../../assets/newImage/jz-alltask.png');
  }
}
.jz3 {
  .img {
    background-image: url('../../../assets/newImage/jz-alltask.png');
  }
}
.jz4 {
  .img {
    background-image: url('../../../assets/newImage/jz-alltask.png');
  }
}
.jz5 {
  .img {
    background-image: url('../../../assets/newImage/jz-alltask.png');
  }
}
.jz6 {
  .img {
    background-image: url('../../../assets/newImage/jz-alltask.png');
  }
}
</style>