<!-- 无人机执行任务总量图表 -->
<template>
  <div class="main">
    <div class="header">
      <div class="card card-right">
        <img class="" src="@/assets/newImage/jz-alltask.png" alt="" />
        <div class="right">
          <span class="text">总任务量</span>
          <span class="num">{{ totalCount }}km</span>
        </div>
      </div>
      <div class="card card-left">
        <!-- <el-button type="primary" :class="{ 'select-btn': btnStyleFlag==1 }" class="unselect-btn" round size="small" @click="changeHandler(1)">今日</el-button> -->
        <el-button type="primary" :class="[btnStyleFlag == 1 ? 'select-btn' : 'unselect-btn']" round size="small"
          @click="changeHandler(1)">今日</el-button>
        <el-button type="primary" :class="[btnStyleFlag == 2 ? 'select-btn' : 'unselect-btn']" round size="small"
          @click="changeHandler(2)">本月</el-button>
        <el-button type="primary" :class="[btnStyleFlag == 3 ? 'select-btn' : 'unselect-btn']" round size="small"
          @click="changeHandler(3)">总数</el-button>
      </div>
    </div>
    <div id="uav"></div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
export default {
  data () {
    return {
      names: [],
      today: [],
      yue: [],
      zongshu: [],
      btnStyleFlag: 3,
      yLabelData: [],
      seriesData: [],
      chartTitle: '总数',
      totalCount: 0,
    }
  },
  async mounted () {
    await this.abc();
    //默认显示总数
    this.chartTitle = '总数';
    this.seriesData = this.zongshu;
    this.totalCount = 0;
    this.zongshu.forEach(item => {
      this.totalCount += parseInt(item);
    });
    this.getEcharts();
  },
  methods: {
    async abc () {
      await API.renwu().then((res) => {
        console.log(res, "无人机执行任务总量图表");
        res.forEach(item => {
          // 各部门名
          this.names.push(item.orgName)
          // 今日数据
          this.today.push(item.todayCount)
          // 本月数据
          this.yue.push(item.monthCount)
          // 总数
          this.zongshu.push(item.allCount)
        });
      });

    },
    getEcharts () {
      // let a = JSON.parse(JSON.stringify(this.names))
      // let b = JSON.parse(JSON.stringify(this.today))
      // let c = JSON.parse(JSON.stringify(this.yue))
      // let d = JSON.parse(JSON.stringify(this.zongshu))

      let color1 = new this.$echarts.graphic.LinearGradient(
        0, 0, 1, 0,
        [
          { offset: 0, color: "#0976EB" },
          { offset: 1, color: "#1BCDBB" },
        ],
        false
      );
      let color2 = new this.$echarts.graphic.LinearGradient(
        0, 0, 1, 0,
        [
          { offset: 0, color: "#0976EB" },
          { offset: 1, color: "#1BCDBB" },
        ],
        false
      );
      let color3 = new this.$echarts.graphic.LinearGradient(
        0, 0, 1, 0,
        [
          { offset: 0, color: "#0976EB" },
          { offset: 1, color: "#1BCDBB" },
        ],
        false
      );
      const myChart = this.$echarts.init(document.getElementById("uav"));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        color: ["#F8CE55FF", "#0CDED3FF", "#44AFFFFF"],
        legend: {
          data: ["    今日", "    本月", "    总数"],
          //   color:['#F8CE55FF','#0CDED3FF','#44AFFFFF']
          textStyle: {
            fontSize: 14, //字体大小
            color: "auto", //字体颜色
          },
          left: "0",
          top: "1",
          itemGap: 40,
          itemWidth: 24, //图例标记的图形宽度
          itemHeight: 10, //图例标记的图形高度
          show: false,

        },
        grid: {
          top: "5%",
          left: "1%",
          right: "1%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
          axisTick: {
            show: false, // 不显示坐标轴刻度线
          },
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          axisLabel: {
            show: false, // 不显示坐标轴上的文字
          },
          splitLine: {
            show: false, // 不显示网格线
          },
        },
        yAxis: {
          type: "category",
          data: this.names,
          // data: this.yLabelData,
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#AAB7C3", //Y轴内容文字颜色
              fontSize: "12",
            },
            padding: [4, 10, 0, 0],
            align: 'right',
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
            textStyle: {
              color: "none",
            },
          },
        },
        series: [
          {
            name: this.chartTitle,
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            barWidth: 6,
            emphasis: {
              focus: "series",
            },
            textStyle: {
              //   color: "#F8CE55FF",
            },
            color: color1,
            // data: this.today,
            data: this.seriesData,
            label: {
              normal: {
                position: "inner",
                show: false,
                formatter: "",
              },
            },
            showBackground: true,
            backgroundStyle: {
              color: '#213046'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [6, 6, 6, 6],
              },
            },

          },
          // {
          //   name: "本月",
          //   type: "bar",
          //   stack: "total",
          //   label: {
          //     show: true,
          //   },
          //   emphasis: {
          //     focus: "series",
          //   },
          //   color: color2,
          //   data: this.yue,
          //   label: {
          //     normal: {
          //       position: "inner",
          //       show: false,
          //       formatter: "",
          //     },
          //   },
          //   showBackground: true,
          //   backgroundStyle: {
          //     color: '#213046'
          //   },
          //   itemStyle: {
          //     normal: {
          //       //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
          //       barBorderRadius: [10, 10, 0, 0],
          //     },
          //   },

          // },
          // {
          //   name: "总数",
          //   type: "bar",
          //   stack: "total",
          //   label: {
          //     show: true,
          //   },
          //   color: color3,
          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: this.zongshu,
          //   label: {
          //     normal: {
          //       position: "inner",
          //       show: false,
          //       formatter: "",
          //     },
          //   },
          //   label: {
          //     normal: {
          //       position: "inner",
          //       show: false,
          //       formatter: "",
          //     },
          //   },
          //   showBackground: true,
          //   backgroundStyle: {
          //     color: '#213046'
          //   },
          //   itemStyle: {
          //     normal: {
          //       //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
          //       barBorderRadius: [6, 6, 6, 6],
          //     },
          //   },

          // },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    /**
    * description: 今日、本月、总数数据切换
    * author: zhangyangyang
    * createtime:2023/06/29 11:43:22
    * flag 1 今日，2 本月，3 总数
    */
    changeHandler (flag) {
      this.btnStyleFlag = flag;
      if (flag == 1) {
        this.chartTitle = '今日';
        this.seriesData = this.today;
        this.totalCount = 0;
        this.today.forEach(item => {
          this.totalCount += parseInt(item);
        });

        this.getEcharts();
      } else if (flag == 2) {
        this.chartTitle = '本月';
        this.seriesData = this.yue;
        this.totalCount = 0;
        this.yue.forEach(item => {
          this.totalCount += parseInt(item);
        });

        this.getEcharts();
      } else {
        this.chartTitle = '总数';
        this.seriesData = this.zongshu;
        this.totalCount = 0;
        this.zongshu.forEach(item => {
          this.totalCount += parseInt(item);
        });

        this.getEcharts();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
}

.header {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0 6px;

  .card-right {
    width: 30%;
    // background-color: aquamarine;
    display: flex;
    margin-left: 5px;

    .right {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text {
        font-size: 12px;
        color: #c0c0c0;
      }

      .num {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
      }
    }


  }

  .card-left {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

#uav {
  width: 100%;
  height: 80%;
}

.select-btn {
  background-color: #017AFF;
  border: 0;
}

.unselect-btn {
  background-color: transparent;
  border: 1px solid #1E2C43;
}</style>