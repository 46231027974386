<!-- 人员统计表格 -->
<template>
  <div class="renyuanTab">
    <!-- 此处需要滚动动画所以我用手写表格 -->
    <div class="bd-box">
      <div class="tb-hd-box njj">
        <div class="danwei">所属单位</div>
        <div class="tb-hd">人员总数</div>
        <div class="tb-hd">民警</div>
        <div class="tb-hd">辅警</div>
        <div class="tb-hd">警航人员</div>
      </div>
      <div class="tb-bd-box" id="hezi">
        <div class="tb-tr njj" v-for="(item, index) in arr" :key="index" :class="[index%2!=1 ? 'tb-tr-odd': '']">
          <!-- 所属单位 -->
          <div class="danwei">{{ item.orgName }}</div>
          <!-- 人员总数 -->
          <div class="td white">{{ item.personTotal }}</div>
          <!-- 民警 -->
          <div class="td white">{{ item.policeTotal }}</div>
          <!-- 辅警 -->
          <div class="td white">{{ item.auxiliaryPoliceTotal }}</div>
          <!-- 警航人员 -->
          <div class="td white">{{ item.aviationPoliceTotal }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
export default {
  data() {
    return {
      arr: [],
    };
  },
  async mounted() {
    await this.getApi();
  },
  methods: {
    async getApi() {
      await API.renyuantongji().then((res) => {
        console.log(res, "人员统计表格");
        this.arr = res.orgUserCount;
        console.log(this.arr, "人员统计表格2");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.renyuanTab {
  width: 100%;
  height: 100%;
  .bd-box {
    width: 100%;
    height: 100%;
    .tb-hd-box {
      width: 100%;
      height: 32px;
      // box-shadow: inset 0px 0px 18px 2px rgba(56, 147, 192, 0.5);
      // border: 1px solid rgba(31, 222, 233, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      // color: #ffffff;
      color: #8591A6;
      font-weight: bold;
    }
    .tb-bd-box {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #aab7c3;
      height: 82%;
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 8px 0 0 0;
      .tb-tr {
        width: 100%;
        height: 32px;
        // border: 1px solid rgba(31, 222, 233, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 8px 0;
        .shijian {
          width: 294px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .tb-tr-odd{
        background-color: #1E2D43;
      }
    }
    .white{
      color: #fff;
    }
  }
}
.tb-bd-box::-webkit-scrollbar {
  width: 0 !important;
}
.danwei {
  width: 130px !important;
  text-align: center;
}
.tb-hd {
  flex: 1;
  text-align: center;
}
.td {
  flex: 1;
  text-align: center;
  white-space: nowrap;
}
.njj {
  padding: 0 15px 0 0;
}
</style>