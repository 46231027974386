<!-- 2022年度无人机飞行里程 -->
<template>
  <div id="uavlc">
    <div id="echarts" style="width: 50%; height: 100%"></div>
    <div class="tuli" style="width: 50%; height: 100%">
      <ul>
        <li class="kuang" v-for="(item, index) in tuArr" :key="index">
          <div class="container">
            <div class="up">
              <div class="left">
                <div class="point-star"></div>
                <div class="text">{{ String(item.name).replace('联飞', '') }}</div>
              </div>
              <div class="right">{{ item.value }}km</div>
            </div>
            <div class="down">
              <el-progress :percentage=item.value/zlc*100 :color="item.color" :show-text="showText" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";

export default {
  data() {
    return {
      arr: [], //echarts数组
      zlc: 0,
      names: [],
      color: ["#FF7759FF", "#A48FF7FF", "#E5ABEDFF", "#88EFFFFF", "#73F9A9FF"], //手写图例颜色库
      tuArr: [], //自定义图例数据
      totalDistance: 1000000,
      showText: false
    };
  },
  async mounted() {
    await this.abc();
    this.getEcharts();
    let dom = document.querySelectorAll('.point-star');
    for (let i = 0; i < dom.length; i++){
      dom[i].style.backgroundColor = `${this.color[i]}`;
    }


  },
  methods: {
    // 数据获取
    async abc() {
      await API.zonglicheng().then((res) => {
        console.log(res, "2022年度无人机飞行里程");
        // debugger
        let a = res.list;
        // this.totalDistance = 0;
        a.forEach((item, index) => {
          this.zlc = this.zlc + item.flightDistances;
          this.arr.push({
            value: parseInt(item.flightDistances),
            name: item.orgName,
          });
          this.tuArr.push({
            name: item.orgName,
            color: this.color[index],
            value: parseInt(item.flightDistances),
          });
        });
      });
    },
    //图表
    getEcharts() {
      //颜色合集
      let color1 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 1, color: "#FF4D32FF" },
          { offset: 0.25, color: "#FFE8C7FF" },
        ],
        false
      );
      let color2 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0.25, color: "#FFC7FDFF" },
          { offset: 1, color: "#540D8FFF" },
        ],
        false
      );
      let color3 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0.25, color: "#B4A1FFFF" },
          { offset: 1, color: "#242E91FF" },
        ],
        false
      );
      let color4 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0.25, color: "#88EFFFFF" },
          { offset: 1, color: "#0A4DE8FF" },
        ],
        false
      );
      let color5 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0.25, color: "#E4FFBAFF" },
          { offset: 1, color: "#00E6A0FF" },
        ],
        false
      );
      const myChart = this.$echarts.init(document.getElementById("echarts"));
      let option = {
        title: [
          {
            text:
              `{name|` +
              parseInt(this.zlc) +
              "}" +
              "\n" +
              "{val|" +
              " " +
              "飞行总里程" +
              "}",
            top: "40%",
            left: "center",
            textStyle: {
              rich: {
                name: {
                  fontSize: 24,
                  color: "#ffffff",
                  padding: [10, 0, 0, 0],
                  fontFamily: "Microsoft YaHei",
                },
                val: {
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#7F8C9D",
                },
              },
            },
          },
        ],
        tooltip: {
          trigger: "item",
        },
        grid: {
          top: "10%",
          left: "10%",
          right: "10%",
          bottom: "10%",
          containLabel: true,
        },
        // color:['#FF4D32FF','#540D8FFF','#242E91FF','#0A4DE8FF','#00E6A0FF'],
        legend: {
          show: false,
          top: "center",
          selectedMode: "false",
          right: "20",
          orient: "hor",
          itemGap: 10,
          textStyle: {
            color: [
              "#FF4D32FF",
              "#540D8FFF",
              "#242E91FF",
              "#0A4DE8FF",
              "#00E6A0FF",
            ],
            rich: {
              // 通过富文本rich给每个项设置样式，下面的oneone、twotwo、threethree可以理解为"每一列"的样式
              a: {
                width: 100,
                align: "left",
                fontSize: "14",
                // borderColor: "rgba(31,222,233,0.5000)",
                // borderWidth: 1,
                color: "#FF7759FF",
              },
              a1: {
                width: 100,
                align: "left",
                fontSize: "14",
                color: "#A48FF7FF",
              },
              a2: {
                width: 100,
                align: "left",
                fontSize: "14",
                color: "#E5ABEDFF",
              },
              a3: {
                width: 100,
                align: "left",
                fontSize: "14",
                color: "#88EFFFFF",
              },
              a4: {
                width: 100,
                align: "left",
                fontSize: "14",
                color: "#73F9A9FF",
              },
              b: {
                // width: 25,
                // align: "right",
                color: "#ffffff",
                fontSize: "14",
              },
              c: {
                width: 50,
                color: "#ffffff",
                fontFamily: "UniDreamLED",
                fontSize: "20",
              },
              d: {
                borderColor: "rgba(31,222,233,0.5000)",
                borderWidth: 1,
              },
            },
          },
          itemWidth: 0, // 图例图形的宽度
          itemHeight: 0, // 图例图形的高度
          formatter: function (name) {
            let data = option.series[0].data;
            let tarValue = 0;
            for (let i = 0; i < data.length; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value;
              }
            }
            if (name == "亭湖分局") {
              return [
                "{a|" +
                  name +
                  "}" +
                  "{c|" +
                  tarValue +
                  "}" +
                  "{b|" +
                  "KM" +
                  "}",
              ];
            } else if (name == "盐南分局") {
              return [
                "{a1|" +
                  name +
                  "}" +
                  "{c|" +
                  tarValue +
                  "}" +
                  "{b|" +
                  "KM" +
                  "}",
              ];
            } else if (name == "经开区分局") {
              return [
                "{a2|" +
                  name +
                  "}" +
                  "{c|" +
                  tarValue +
                  "}" +
                  "{b|" +
                  "KM" +
                  "}",
              ];
            } else if (name == "盐都分局") {
              return [
                "{a3|" +
                  name +
                  "}" +
                  "{c|" +
                  tarValue +
                  "}" +
                  "{b|" +
                  "KM" +
                  "}",
              ];
            } else if (name == "市局警种部门") {
              return [
                "{a4|" +
                  name +
                  "}" +
                  "{c|" +
                  tarValue +
                  "}" +
                  "{b|" +
                  "KM" +
                  "}",
              ];
            }

            // return arr.join("\n");
            // return arr;
          },
          //   borderColor: "rgba(31,222,233,0.5000)",
          //   borderWidth: 1,
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            silent: true, //禁用地图的hover事件
            radius: ["53%", "60%"],
            // center: ["25%", "50%"],
            color: [color1, color2, color3, color4, color5],
            itemStyle: {},
            legendHoverLink: false,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.arr,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#uavlc {
  width: 100%;
  height: 100%;
  display: flex;
}
.tuli {
}
ul {
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.kuang {
  // width: 87%;
  width: 95%;
  // height: 16px;
  height: 16%;
  // box-shadow: inset 0px 0px 4px 2px rgba(56, 147, 192, 0.5);
  // border: 1px solid rgba(31, 222, 233, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 4px 8px 4px 10px;
  .container{
    width: 100%;
    height: 100%;
    .up{
      width: 100%;
      height: 70%;
    }
    .down{
      width: 100%;
      height: 30%;
    }
  }

  .up{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left{
      display: flex;
      width: 60%;
      align-items: center;
      .point-star{
        background-color: aquamarine;
        width: 6px;
        height: 6px;
      }
      .text{
        margin-left: 10px;
        font-size: 12px;
        color: #c0c0c0;
      }
    }
    .right{
      width: 40%;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      color: #d3d3d3;
      letter-spacing: 2px;
    }
  }
  .down{
    display: flex;
    align-items: flex-end;
  }
}
.fontOne {
  font-size: 20px;
  font-family: UniDreamLED;
  color: #ffffff;
}
.fontTwo {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.fontThree {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.jianju {
  margin: 0 0 0 8px;
}
/deep/ .el-progress{
  width: 100%;
}
/deep/ .el-progress-bar__outer{
  background-color: #213046;
  height: 3px !important;
}
</style>
