<!-- 设备统计 -->
<template>
  <div class="main">
    <div class="header">
      <div class="card card-right">
        <img class="" src="@/assets/newImage/jz-alldevice.png" alt="" />
        <div class="right">
          <span class="text">设备总数</span>
          <span class="num">{{ totalCount }}</span>
        </div>
      </div>
      <div class="card card-left">
        <img class="" src="@/assets/newImage/normal.png" alt="" />
        <div class="right">
          <!-- <span class="text">正常</span> -->
          <span class="zhengchang" :class="{ a: val == '损坏', b: val == '维修', c: val == '保养' }">{{ val }}</span>
          <span class="num">{{ totalCount }}</span>
        </div>
      </div>
    </div>
    <div id="uavshebei"></div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
import bus from "@/utils/bus.js";
export default {
  data () {
    return {
      val: "正常",
      arr: [],
      names: [],
      shuju: [],
      zheng: [], //正常数组
      sun: [], //损坏数组,
      bao: [], //保养数组,
      wei: [], //维修数组,
      totalCount: 0,
    };
  },
  async mounted () {
    await this.abc();
    await this.newAr();
    this.getEcharts();
  },
  methods: {
    // 获取数据
    async abc () {
      await API.shebeitj().then((res) => {
        console.log(res, "设备统计");
        this.totalCount = 0;
        this.arr = res;
        res.forEach((res) => {
          this.totalCount += parseInt(res.count);
          if (res.status == "repair") {
            this.wei.push(res);
          } else if (res.status == "normal") {
            this.zheng.push(res);
          } else if (res.status == "damage") {
            this.sun.push(res);
          } else if (res.status == "care") {
            this.bao.push(res);
          }
        });

        bus.$emit('totalCount', this.totalCount);
        // console.log(JSON.parse(JSON.stringify(this.zheng)), "正常");
        // console.log(JSON.parse(JSON.stringify(this.sun)), "损坏");
        // console.log(JSON.parse(JSON.stringify(this.bao)), "保养");
        // console.log(JSON.parse(JSON.stringify(this.wei)), "维修");
      });
    },
    // 根据状态挑选数据 this.names.push(String(res.name).replace('联飞', '').replace('中航智', ''));
    newAr () {
      this.names = [];
      this.shuju = [];
      if (this.val == "正常") {
        this.zheng.forEach((res) => {
          // this.names.push(res.name);
          this.names.push(String(res.name).replace('联飞', '').replace('中航智', ''));
          this.shuju.push(res.count);
        });
        this.getEcharts();
      } else if (this.val == "损坏") {
        this.sun.forEach((res) => {
          // this.names.push(res.name);
          this.names.push(String(res.name).replace('联飞', '').replace('中航智', ''));
          this.shuju.push(res.count);
        });
        this.getEcharts();
      } else if (this.val == "保养") {
        this.bao.forEach((res) => {
          // this.names.push(res.name);
          this.names.push(String(res.name).replace('联飞', '').replace('中航智', ''));
          this.shuju.push(res.count);
        });
        this.getEcharts();
      } else if (this.val == "维修") {
        this.wei.forEach((res) => {
          // this.names.push(res.name);
          this.names.push(String(res.name).replace('联飞', '').replace('中航智', ''));
          this.shuju.push(res.count);
        });
        this.getEcharts();
      }
    },
    // 当前统计状态,修改图表显示内容
    zhuangtai (stu) {
      this.val = stu;
      this.newAr();
    },
    // 图表
    getEcharts () {
      // 颜色
      let color1 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          // { offset: 0, color: "#251000FF" },
          // { offset: 0.5, color: "#965E11FF" },
          // { offset: 1, color: "#FFDCA2FF" },
          { offset: 0, color: "#1A6ABF" },
          { offset: 0.5, color: "#34A7C3" },
          { offset: 1, color: "#57D0BA" },
        ],
        false
      );
      let color2 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000000FF" },
          { offset: 0.5, color: "#608731FF" },
          { offset: 1, color: "#F2FDAAFF" },
        ],
        false
      );
      let color3 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000000FF" },
          { offset: 0.5, color: "#2E8F87FF" },
          { offset: 1, color: "#B7FFF9FF" },
        ],
        false
      );
      let color4 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#000218FF" },
          { offset: 0.5, color: "#4C4EBCFF" },
          { offset: 1, color: "#BEBCFFFF" },
        ],
        false
      );
      let color5 = new this.$echarts.graphic.LinearGradient(
        0,
        1,
        0,
        0,
        [
          { offset: 0, color: "#1B0225FF" },
          { offset: 0.5, color: "#743ACCFF" },
          { offset: 1, color: "#CFA6FFFF" },
        ],
        false
      );
      const myChart = this.$echarts.init(document.getElementById("uavshebei"));
      let option = {

        tooltip: {
          trigger: "axis",
          extraCssText: 'width:100px;height:40px;background:transparent;',
          axisPointer: {
          },
          formatter: (params) => {
            // console.log(params, 'params');
            let res = `
            <div style="
              background-color: #295183; 
              height: 60px; 
              width: 80px;
              border: 0;
              border-top: 4px solid #3C7CC2;
              padding:0;
              margin: 0;
              padding-left: 20%;
              display: flex;
              justify-content: center;
              flex-direction: column;
            ">
                  <div style="font-size: 22px; color: #fff">${params[0].data}</div>
                  <div style="font-size: 12px; color: #6884AC; margin-top: 5px">${params[0].name}</div>
            </div>
                `;
            return res;
          }
        },
        legend: {
          show: "false",
          top: "15",
          left: "left",
          itemWidth: 0, // 图例图形的宽度
          itemHeight: 0, // 图例图形的高度
          textStyle: {
            color: "#AAB7C3",
          },
        },
        grid: {
          top: '10%',
          left: '11%',
          right: '1%',
          bottom: "20%",
        },
        xAxis: {
          show: true,
          type: "category",
          data: this.names,
          axisLabel: {
            color: "#AAB7C3",
            fontSize: "13",
            interval: 0,
          },
          axisLine: {
            lineStyle: {
              type: "dashed", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //y轴文字的配置
            textStyle: {
              color: "#AAB7C3", //Y轴内容文字颜色
              fontSize: "12",
            },
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "sloid",
              color: ['#1A283F'],
            },
          },
        },
        series: [
          {
            data: this.shuju,
            // name: "当前状态统计 :  次",
            type: "bar",
            backgroundStyle: {
              //   color: [color1, color2, color3, color4, color5],
            },
            label: {
              normal: {
                show: false,
                position: 'top'
              },
              formatter: "{@value}"
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [color1, color1, color1, color1, color1];
                  return colorList[params.dataIndex];
                },
                barBorderRadius: [6, 6, 6, 6],
              },
            },
            barWidth: 8,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
}

.header {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0 6px;

  .card-right {
    width: 30%;
    // background-color: aquamarine;
    display: flex;
    margin-left: 5px;

    .right {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text {
        font-size: 12px;
        color: #c0c0c0;
      }

      .num {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
      }
    }


  }

  .card-left {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    color: #59677E;
    font-size: 14px;

    .right {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text {
        font-size: 12px;
        color: #c0c0c0;
      }

      .num {
        // font-size: 18px;
        // color: #fff;
        // font-weight: 600;
        font-size: 12px;
        color: #c0c0c0;
      }
    }

  }
}

#uavshebei {
  width: 100%;
  height: 80%;
}





// .shebeiguanli {
//   width: 100%;
//   height: 26vh;
//   position: relative;
// }

// #uavshebei {
//   width: 100%;
//   height: 26.5vh;
// }

.dangqian {
  position: absolute;
  top: 10px;
  left: 4px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #aab7c3;
}

.jj {
  margin: 0 6px 0 4px;
}

.zhengchang {
  color: #00d45cff;
  font-weight: 600;
  font-size: 16px;
}

.a {
  color: #ff4e0eff !important;
}

.b {
  color: #d4be00ff !important;
}

.c {
  color: #00c1d4ff !important;
}
</style>