<template>
  <div class="boss">
    <div class="chang">
      <!-- 标题 -->
      <div class="head wih100 h60 cp" @click="routerFn">
        <img class="dib wih100 ht100" src="@/assets/newImage/bossTou.png" alt="" />
        <!-- <span class="header-title">联合飞机无人机数据中心</span> -->
        <span class="header-title">{{ headerTitle }}</span>
      </div>
      <!-- 详情 -->
      <xiangqing class="gaodu" v-show="1"></xiangqing>
      <!-- 主体 -->
      <div class="zhuti">
        <!-- 左边部分 -->
        <div class="zuo zuoyou">
          <!-- 无人机执行任务总量 -->
          <div class="card card1">
            <div class="title">任务情况</div>
            <div class="body">
              <uavrenwu></uavrenwu>
            </div>
          </div>
          <!-- 无人机飞行总里程 -->
          <div class="card card2">
            <div class="title">2023无人机飞行里程</div>
            <div class="body">
              <uarzonglicheng></uarzonglicheng>
            </div>
          </div>
          <!-- 2023年度无人机飞行架次 -->
          <div class="card card3">
            <div class="title">2023无人机飞行架次</div>
            <div class="body">
              <uavfxjc></uavfxjc>
            </div>
          </div>
        </div>
        <!-- 中间 -->
        <div class="zhong">
          <div class="cap">
            <!-- 标签 -->
            <div class="biaoqian">
              <div class="kaung jb uav" @click="uav" :class="{ uavjin: jinId == 1 }">
                <div class="imgone"></div>
                <div class="text-num">
                  <span class="text">无人机在线</span>
                  <div class="">
                    <span class="num">{{ uavzx.online ? uavzx.online : 0 }}</span>
                  </div>
                </div>
              </div>
              <div class="kaung jb yc" :class="{ ycjin: jinId == 2 }" @click="yc">
                <div class="imgtwo"></div>
                <div class="text-num">
                  <span class="text">机巢在线</span>
                  <div class="">
                    <span class="num">{{ yczx.online ? yczx.online : 0 }}</span>
                  </div>
                </div>

              </div>
              <div class="kaung jb jizhan" :class="{ jizhanjin: jinId == 3 }" @click="jizhan">
                <div class="imgthree"></div>
                <div class="text-num">
                  <span class="text">基站总数</span>
                  <div class="">
                    <span class="num">{{ jizhanzx.count ? jizhanzx.count : 0 }}</span>
                  </div>
                </div>
              </div>
              <!-- <div
                class="kaung jb fs"
                :class="{ fsjin: jinId == 4 }"
                @click="fs"
              >
                <div class="imgfour"></div>
                <span class="fontOne kq">飞手在线</span>
                <div class="fontOne chuizhi">
                  <span class="shuliang">{{ feishou.online }}</span>
                  <span>个</span>
                </div>
              </div> -->
            </div>
            <ditu ref="cesium_layer"></ditu>
          </div>
          <!-- 中间底下 -->
          <div class="biaoge">
            <div class="title">任务情况</div>
            <div class="body">
              <dangqianrenwu></dangqianrenwu>
            </div>
          </div>
        </div>
        <!-- 右边 -->
        <div class="you zuoyou">
          <!-- 人员统计 -->
          <div class="card card1">
            <div class="title">人员统计</div>
            <div class="body">
              <!-- 人员统计 -->
              <div class="item-c rytj">
                <renyuan></renyuan>
              </div>
              <!-- 人员表格 -->
              <div class="item-c rybg">
                <renyuanTab></renyuanTab>
              </div>
            </div>
          </div>
          <!-- 设备统计 -->
          <div class="card card3">
            <div class="title">设备统计</div>
            <div class="body">
              <shebei ref="sheb"></shebei>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 组件引用
import xiangqing from "./particulars/index.vue";
//无人机执行任务总量
import uavrenwu from "./UAVTotalNumberOfExecutedTasks/index.vue";
// 年度无人机飞行里程
import uarzonglicheng from "./UAVTotalMileageFlown/index.vue";
// 年度无人机飞行架次
import uavfxjc from "./uavNumberOfFlight";
// 人员统计
import renyuan from "./PersonnelStatistics/index.vue";
// 设备统计
import shebei from "./deviceStatistics/index.vue";
// 当前任务表格
import dangqianrenwu from "./currentTask/index.vue";
// 人员统计表格
import renyuanTab from "./PersonnelForm/index.vue";
// 引入地图
import ditu from "./map/index.vue";

import gsap from 'gsap';

// import ditu from "../command/monitoring/components/map/index.vue";
// 引入地图弹窗方法
import Popup from "./methods/popup";
import PopupV2 from "./methods/popupV2";
import { Viewer, Utils } from "@/lib/cesium";
import API from "@/api";
// import Config from "@/assets/config.json";
let esriMapLayer = new Cesium.ImageryLayer(new Cesium.ArcGisMapServerImageryProvider({
  url: "http://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer",
}), {
  show: true,
  alpha: 1
});
export default {
  // inject: ["g_cesium_layer"],
  data () {
    return {
      jinId: 99,
      uavzx: {},
      yczx: {},
      jizhanzx: {},
      feishou: {},
      title: {
        id: 1,
        default: "icon1",
        active: "icon7",
        componentType: "Map",
        title: "无人机",
      },
      flag: true,
      status: true,
      online: 1,
      collection: {
        zysb: null,
        jwys: null,
        gzsb: null,
        jlys: null,
        kfq: null,
        zddq: null,
      },
      sl: 1,
      yingcaho: {
        id: 2,
        default: "icon2",
        active: "icon8",
        componentType: "Map",
        title: "机巢",
      },
      djz: {
        id: 3,
        default: "icon3",
        active: "icon9",
        componentType: "Map",
        title: "基站",
      },
      headerTitle: '联合飞机无人机数据中心',
    };
  },
  components: {
    xiangqing,
    uavrenwu,
    uarzonglicheng,
    uavfxjc,
    renyuan,
    shebei,
    dangqianrenwu,
    renyuanTab,
    ditu,
  },
  computed: {
    cesium_layer () {
      return this.$refs["cesium_layer"];
    },
  },
  mounted () {

    let sceneConfigData = window.localStorage.getItem('sceneConfigData');
    sceneConfigData = JSON.parse(sceneConfigData);
    if (sceneConfigData && sceneConfigData['data_center_title'] && sceneConfigData['data_center_title'] != '') {
      this.headerTitle = sceneConfigData['data_center_title'];
    }
    this.abc();
    this.uav();
    this.$nextTick(() => {
      let dom = document.querySelector('.cesium-viewer');
      dom.style.borderRadius = '12px';
      // let viewer = this.$refs.cesium_layer.viewer();
      // console.log(viewer, 'viewerviewerviewerviewer');
      this.addEsriLayer();
    });

    this.addDyLine();
  },
  methods: {
    routerFn () {
      // this.$router.go(-1);
      let { href } = this.$router.resolve({ path: "/home" });
      window.open(href, "_self");
      setTimeout(() => {
        this.$router.go(0);
      }, 100);
    },
    // 地图按钮
    async abc () {
      let res1 = (await API.HOME.dataInfo({ type: 1 })) || { name: "无人机" };
      let res2 = (await API.HOME.dataInfo({ type: 2 })) || { name: "机巢" };
      let res3 = (await API.HOME.dataInfo({ type: 3 })) || { name: "飞手" };
      let res4 = (await API.HOME.dataInfo({ type: 4 })) || { name: "基站" };

      this.uavzx = res1;
      this.yczx = res2;
      this.jizhanzx = res4;
      this.feishou = res3;
      // this.dataList = [res1, res2, res3, res4] || [];
      console.log([res1, res2, res3, res4], "在线数量");
    },

    show_collection (cate) {
      let primitive = this.collection[cate];
      if (Cesium.defined(primitive)) {
        let viewer = this.cesium_layer.viewer();
        console.log(viewer, 'viewerviewerviewerviewerviewerviewer');
        let scene = viewer.scene;
        if (!scene.primitives.contains(primitive)) {
          scene.primitives.add(primitive);
        }
        primitive.show = true;
      }
      console.log("--7");
    },
    init_collection (cate) {
      // let viewer = this.cesium_layer.viewer()
      let viewer = this.$refs.cesium_layer.viewer();
      let scene = viewer.scene;
      if (Cesium.defined(this.collection[cate])) {
        scene.primitives.remove(this.collection[cate]);
      }
      this.collection[cate] = new Cesium.BillboardCollection({
        scene,
        show: true,
      });
      return this.collection[cate];
    },
    // 地图位置接口
    async getlist (id, isAbnormal, status) {
      let res = await API.MONITOR.getList({
        type: id,
        isAbnormal: isAbnormal,
        status: status,
      });
      console.log(res, "caotam");
      this.add_zysb(res, id);
    },
    add_zysb (res, id) {
      let cate_name = "zysb";
      let collection = this.init_collection(cate_name);
      if (res) {
        let list = [];
        if (id == 1) {
          list.push(...res.deviceList);
        } else if (id == 2) {
          list.push(...res.nestList);
        } else if (id == 3) {
          list.push(...res.baseList);
        } else if (id == 4) {
          list.push(...res.landList);
        } else if (id == 5) {
          list.push(...res.mountList);
        } else if (id == 6) {
          list.push(...res.batteryList);
        }
        this.sl = list.length;
        list.forEach((item) => {
          item.longitude *= 1;
          item.latitude *= 1;
          if (item.latitude && item.longitude) {
            collection.add({
              position: Utils.transformWGS842Cartesian({
                lng: item.longitude,
                lat: item.latitude,
                alt: 0,
              }),
              // image: item.category === 4 ? require(`@/assets/images/factor/${cate_name}/${item.category}.png`) :  require(`@/assets/images/factor/${cate_name}/${item.category}${(item.category === 1 && item.status == 0) ? '-disable' : ''}.svg`),
              image: require(`@/assets/images/factor/sjq/${id *
                (item.reportStatus == 0
                  ? 100
                  : item.state == 1
                    ? 1
                    : 10 || item.landingStatus
                      ? 1
                      : 10)
                }.png`),
              scale: 1,
              width: 54,
              height: 89,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              id: JSON.stringify({
                ...item,
                entityType: "zdys",
              }),
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                2.2e5
              ),
              scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            });
          }
        });
        !this.cesium_layer.is_plate_mode() && this.show_collection(cate_name);
      }
      console.log("--6");
    },
    // 无人机在线
    uav () {
      this.jinId = 1;
      this.getlist(this.title.id, null, this.online);
    },
    // 机巢在线
    yc () {
      this.jinId = 2;
      this.getlist(this.yingcaho.id, null, this.online);
    },
    // 基站总数
    jizhan () {
      this.jinId = 3;
      this.getlist(this.djz.id, null, this.online);
    },
    // 飞手在线
    fs () {
      this.jinId = 4;
    },
    sbbtn (command) {
      // 调用子组件方法,子组件设备统计
      this.$refs.sheb.zhuangtai(command);
    },
    addEsriLayer () {
      // // 添加高德影像图
      // let gaodeImageryProvider = new Cesium.UrlTemplateImageryProvider({
      //   url: 'https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
      //   maximumLevel: 18,
      //   minimumLevel: 1,
      //   credit: 'Amap'
      // });
      // let gaodeImgBaseMapLayer = new Cesium.ImageryLayer(gaodeImageryProvider, {
      //   show: true,
      //   alpha: 1
      // });
      //使用arcgis全球影像，不含注记
      let viewer = this.$refs.cesium_layer.viewer();
      viewer.imageryLayers.add(esriMapLayer);
    },
    removeEsriLayer () {
      let viewer = this.$refs.cesium_layer.viewer();
      viewer.imageryLayers.remove(esriMapLayer);
    },
    // 添加动态线
    addDyLine () {
      let viewer = this.$refs.cesium_layer.viewer();
      viewer.scene.postProcessStages.fxaa.enabled = true;

      let po = viewer.entities.add({
        polyline: {
          // 118.66673, 30.81576, 21812.321476599747
          positions: Cesium.Cartesian3.fromDegreesArrayHeights([
            118.48573, 31.05976, 500,
            118.50573, 31.12576, 500,
            118.70573, 31.10576, 500,
            118.60573, 31.04576, 500,
            118.48573, 31.05976, 500,
          ]),
          width: 2,
        },
      })
      let material = this.generateMaterial();
      po.polyline.material = material;
    },
    // 生成材质
    generateMaterial () {
      //自定义materialproperty
      class CustomMaterialPropery {

        constructor() {
          this.definitionChanged = new Cesium.Event();
          this._time = performance.now()
          this.duration = 3000;

          Cesium.Material._materialCache.addMaterial("CustomMaterial", {
            fabric: {
              type: "CustomMaterial",
              uniforms: {
                uTime: 0,
                // image: 'http://openlayers.vip/resources/polylineTrailLinkMaterial.png',
                image: '/image/polylineTrailLinkMaterial-aqua.png',
                // time: this._time,
                time: 0,
                color: Cesium.Color.fromCssColorString("rgba(26, 203, 188, 1)")
              },
              source: `
                czm_material czm_getMaterial(czm_materialInput materialInput)
                {
                  // 生成默认的基础材质
                  czm_material material = czm_getDefaultMaterial(materialInput);
                  // 整个画布大小
                  vec2 st = materialInput.st;
                  vec4 colorImage = texture(image, vec2(fract(st.s / 1.0 - time), st.t));
                  material.alpha = colorImage.a * color.a;
                  // material.alpha = color.a;
                  material.diffuse = (colorImage.rgb+color.rgb)/2.0;
                  // material.diffuse = (colorImage.rgb)/5.0;
                  return material;
                }
          `,
            },
          });

          this.params = {
            uTime: 0,
          };
          gsap.to(this.params, {
            uTime: 1,
            duration: 5,
            repeat: -1,
            yoyo: true,
          });
        }
        getType () {
          // 返回材质类型
          return "CustomMaterial";
        }
        getValue (time, result) {
          // // console.log(result, time);
          // let t = performance.now() / 1000;
          // t = t % 1;
          // console.log(t);
          // result.uTime = t;
          // result.uTime = this.params.uTime;
          // 返回材质值
          result.time = ((performance.now() - this._time) % this.duration) / this.duration
          return result;
        }
      }

      let material = new CustomMaterialPropery();
      return material;
    },
  },
  destroyed () {
    this.removeEsriLayer();
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.head {
  position: relative;

  .header-title {
    position: absolute;
    // top: 50%;
    top: 42%;
    left: 50%;
    color: beige;
    transform: translate(-50%, -50%);
    color: #fff;
    letter-spacing: 3px;
    font-size: 26px;
    font-weight: 500;
  }
}

.boss {
  // background-image: url("~@/assets/newImage/bossbj.png");
  background-size: 100% 100%;

  pointer-events: auto;
  display: flex;
  justify-content: center;

  .chang {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    /* .head {
      width: 100%;
      height: 60px !important;
      img{
        width: 100%;
        height: 100%;
      }
    } */
  }
}

.gaodu {
  height: 9%;
}

.zhuti {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;

  .zuoyou {
    .card {
      height: 32%;
      width: 95%;
      background-color: #132138;
      border-radius: 16px;
      padding: 10px;

      .title {
        height: 12%;
        width: 100%;
        background-image: url('../../assets/newImage/card-title.png');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 8%;
        font-size: 16px;
      }

      .body {
        height: 88%;
        width: 100%;
        // border: 1px solid #fff;
      }
    }
  }

  .zuo {
    width: 24%;
    // background-image: url("~@/assets/newImage/bosszuo.png");
    // background-size: 100% 100%;
    padding: 0 0 0 0;
    height: 99.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;


  }

  .zhong {
    width: 52%;

    // display: none;
    .cap {
      // background-image: url("~@/assets/newImage/bosscap.png");
      background-size: 100% 100%;
      // height: 590px;
      height: 65.2%;
      margin: 0px 0 15px 0;
      position: relative;
      // padding: 16px;
      // border: 1px solid #fff;
      border-radius: 22px;

      .kuang {
        display: flex;
        flex-direction: column;
      }



      .biaoqian {
        z-index: 99;
        position: absolute;
        right: 12px;
        top: 25px;
        height: 356px;
        width: 180px;

        .kaung {
          width: 100%;
          height: 77px;
          // box-shadow: inset 0px 0px 17px 2px rgba(46, 181, 246, 0.5);
          // border: 1px solid rgba(31, 222, 233, 0.5);
          margin: 0 0 16px 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 7px 16px 7px 16px;
          background-color: #132B2C;
          border-radius: 12px;
          display: flex;
          justify-content: flex-start;
        }
      }
    }

    .biaoge {
      // height: 227px;
      height: 32.1%;
      // background-image: url("~@/assets/newImage/bosscap.png");
      background-size: 100% 100%;
      padding: 0 22px 0 22px;
      background-color: #69f3ff;



      height: 32%;
      width: 100%;
      background-color: #132138;
      border-radius: 16px;
      padding: 10px;

      .title {
        height: 12%;
        width: 50%;
        background-image: url('../../assets/newImage/card-title2.png');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 4%;
        font-size: 16px;
      }

      .body {
        height: 88%;
        width: 100%;
        // border: 1px solid #fff;
      }


    }
  }

  .you {
    width: 24%;
    // background-image: url("~@/assets/newImage/bosszuo.png");
    // background-size: 100% 100%;
    //
    height: 99.5%;
    // padding: 0 16px 0 16px;
    padding: 0 0 0 0;
    // height: 99.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .card1 {
      height: 66%;

      .title {
        height: 6%;
        width: 100%;
        background-image: url('../../assets/newImage/card-title.png');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 8%;
        font-size: 16px;
      }

      .body {
        height: 94%;
        width: 100%;
      }

      .item-c {
        height: 50%;
        width: 100%;
      }

      .rybg {
        border-top: 1px solid #1E2D43;
      }
    }
  }
}

.text-num {
  margin-left: 15px;

  .text {
    color: #7A8F8F;
    font-size: 14px;
  }

  .num {
    color: #fff;
    font-size: 24px;
  }
}




// 18号字体,白色
.fontOnes {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 25px;
}

// 18 白色 有影音
.fontThree {
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  // line-height: 24px;
  text-shadow: 0px 0px 6px rgba(0, 187, 255, 0.7);
}

//flex 并且居边
.jb {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

// 标签背景,以及鼠标悬浮
.uav {
  .imgone {
    width: 36px;
    height: 36px;
    background-image: url("~@/assets/newImage/uav-online.png");
    background-size: 100% 100%;
  }
}

.uav:hover {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;

  .imgone {
    width: 36px;
    height: 36px;
    background-image: url("~@/assets/newImage/uav-online.png");
    background-size: 100% 100%;
  }
}

.yc {
  .imgtwo {
    // width: 63px;
    // height: 63px;
    // background-image: url("~@/assets/newImage/bossyingchao.png");
    // background-size: 100% 100%;


    width: 36px;
    height: 36px;
    background-image: url("~@/assets/newImage/jichao-online.png");
    background-size: 100% 100%;

  }
}

.yc:hover {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;

  .imgtwo {
    // width: 63px;
    // height: 63px;
    // background-image: url("~@/assets/newImage/bossyingchaojin.png");
    // background-size: 100% 100%;
    width: 36px;
    height: 36px;
    background-image: url("~@/assets/newImage/jichao-online.png");
    background-size: 100% 100%;
  }
}

.jizhan {
  .imgthree {
    // width: 63px;
    // height: 63px;
    // background-image: url("~@/assets/newImage/bossxinhaotaJin.png");
    // background-size: 100% 100%;
    width: 36px;
    height: 36px;
    background-image: url("~@/assets/newImage/jizhan-zongshu.png");
    background-size: 100% 100%;
  }
}

.jizhan:hover {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;

  .imgthree {
    // width: 63px;
    // height: 63px;
    // background-image: url("~@/assets/newImage/bossxinhaotaJin.png");
    // background-size: 100% 100%;
    width: 36px;
    height: 36px;
    background-image: url("~@/assets/newImage/jizhan-zongshu.png");
    background-size: 100% 100%;
  }
}

.fs {
  .imgfour {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossfeishou.png");
    background-size: 100% 100%;
  }
}

.fs:hover {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;

  .imgfour {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossfeishouJin.png");
    background-size: 100% 100%;
  }
}

.bioati {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .neirong {
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("~@/assets/newImage/bossneirongkuang.png");
    background-size: 100% 100%;
  }

  .sexianshang {
    position: absolute;
    left: 0;
    top: 0;
    width: 977px;
    height: 1px;
    border-bottom: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }

  .sexianxia {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 977px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }
}

// 点击效果
.uavjin {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;

  .imgone {
    width: 36px;
    height: 36px;
    background-image: url("~@/assets/newImage/uav-online.png");
    background-size: 100% 100%;
  }
}

.ycjin {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;

  .imgtwo {
    // width: 63px;
    // height: 63px;
    // background-image: url("~@/assets/newImage/bossyingchaojin.png");
    // background-size: 100% 100%;

    width: 36px;
    height: 36px;
    background-image: url("~@/assets/newImage/jichao-online.png");
    background-size: 100% 100%;
  }
}

.jizhanjin {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;

  .imgthree {
    // width: 63px;
    // height: 63px;
    // background-image: url("~@/assets/newImage/bossxinhaotaJin.png");
    // background-size: 100% 100%;
    width: 36px;
    height: 36px;
    background-image: url("~@/assets/newImage/jizhan-zongshu.png");
    background-size: 100% 100%;

  }
}

.fsjin {
  box-shadow: inset 0px 0px 20px 6px rgba(255, 210, 87, 0.63) !important;
  border: 1px solid #ffe008 !important;

  .imgfour {
    width: 63px;
    height: 63px;
    background-image: url("~@/assets/newImage/bossfeishouJin.png");
    background-size: 100% 100%;
  }
}

// 小框标题样式
.zongliang {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;

  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }

  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }
}

// 图表div样式
.uavzl {
  width: 100%;
  // height: 165px;
  height: 20%;
  margin: 22px 0 26px 0;
}

.uavlicheng {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;

  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }

  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }
}

.uavfxlc {
  width: 100%;
  height: 19%;
  margin: 22px 0 23px 0;
}

.uavjiaci {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;

  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }

  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }
}

.uavjc {
  width: 100%;
  height: 28%;
  margin: 22px 0 0px 0;
}

// 右边图表栏
.renyuan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;

  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }

  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }
}

.uavrenyuan {
  width: 100%;
  height: 18%;
  margin: 22px 0 23px 0;
}

.shebei {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;

  .shangxian {
    position: absolute;
    top: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }

  .xiaxian {
    position: absolute;
    bottom: 0;
    // left: 0;
    width: 372px;
    height: 1px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg,
        rgba(0, 189, 255, 0),
        rgba(162, 245, 255, 1),
        rgba(0, 189, 255, 0)) 2 2;
    filter: blur(0px);
  }

  .btn {
    position: absolute;
    right: 4%;
    top: 21%;
    width: 24px;
    height: 23px;
    cursor: pointer;
  }
}

.uavshebei {
  width: 100%;
  height: 28%;
  margin: 22px 0 23px 0;
}

// 人员表格
.Tabrenyuan {
  width: 100%;
  height: 28%;
  margin: 0 0 0px 0;
}

// 当前任务
.dangqianrenwu {
  margin: 20px 0 11px 0;
  width: 100%;
  height: 60%;
}

// 弹框样式

.abcd {
  background: rgba(104, 104, 104, 0.5) !important;
  border: 1px solid transparent !important;
  border-radius: 0px;
  width: 79px !important;

  .el-dropdown-menu__item {
    background: none;
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #aab7c3 !important;
    text-align: center;

    &:hover {
      background: #429ecc !important;
      color: #ffffff !important;
    }
  }
}

.kq {
  width: 100px;
  text-align: left;
}

.shuliang {
  width: 50px;
  text-align: right;
  display: inline-block;
  font-size: 40px;
  font-family: UniDreamLED;
  color: #69f3ff;
  margin: 0 10px 0 0;
}

.chuizhi {
  display: flex;
  align-items: center;
}
</style>
