<!-- 人员统计 -->
<template>
  <div class="renyuantu">
    <div id="renyuan"></div>
    <div class="tuli">
      <ul>
        <!-- <li class="kuang" v-for="(item, index) in arr" :key="index">
          <div :style="{ color: item.color }" class="fontThree">
            {{ item.name }}
          </div>
          <div class="flexs">
            <span class="fontOne juzhong">{{ item.value }}</span>
            <span class="fontTwo jianju">人</span>
          </div>
        </li> -->


        <li class="kuang" v-for="(item, index) in arr" :key="index">
          <div class="container">
            <div class="up">
              <div class="left">
                <div class="point-star"></div>
                <div class="text">{{ String(item.name).replace('联飞', '') }}</div>
              </div>
              <div class="right">{{ item.value }}人</div>
            </div>
            <div class="down">
              <el-progress :percentage=item.value/zrs*100 :color="item.color" :show-text="showText" />
            </div>
          </div>
        </li>


      </ul>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/ScreenDisplay";
import bus from "@/utils/bus.js";
export default {
  data() {
    return {
      arr: [],
      tuArr: [], //图例数组
      color: ["#C174F3", "#29C2A1FF", "#287DE3FF"],
      zrs: 0,
      showText: false,
    };
  },
  async mounted() {
    await this.abc();

    bus.$emit('useCount', this.zrs);

    this.getEcharts();
    let dom = document.querySelectorAll('.point-star');
    for (let i = 0; i < dom.length; i++){
      dom[i].style.backgroundColor = `${this.color[i]}`;
    }


  },
  methods: {
    async abc() {
      await API.renyuantongji().then((res) => {
        console.log(res, "人员统计");
        let a = res.fujingCount;
        let b = res.jinghangCount;
        let c = res.minjingCount;
        this.zrs = parseInt(a) + parseInt(b) + parseInt(c);
        console.log(this.arr, " this.ar");
        this.arr = [
          { value: a, name: "机长", color: this.color[0] },
          { value: b, name: "驾驶员", color: this.color[1] },
          { value: c, name: "飞行助理", color: this.color[2] },
        ];

      });
    },
    getEcharts() {
      const myChart = this.$echarts.init(document.getElementById("renyuan"));
      let option = {
        title: [
          {
            text:
              `{name|` +
              parseInt(this.zrs) +
              "}" +
              "\n" +
              "{val|" +
              " " +
              "总人数" +
              "}",
            top: "40%",
            left: "center",
            textStyle: {
              rich: {
                name: {
                  fontSize: 24,
                  color: "#ffffff",
                  padding: [10, 0, 0, 0],
                  fontFamily: "Microsoft YaHei",
                },
                val: {
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#7F8C9D",
                },
              },
            },
          },
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: false,
          top: "center",
          selectedMode: "multiple",
          right: "20",
          orient: "hor",
          itemGap: 30,
          textStyle: {
            color: "auto",
            rich: {
              // 通过富文本rich给每个项设置样式，下面的oneone、twotwo、threethree可以理解为"每一列"的样式
              a: {
                width: 90,
                align: "left",
                fontSize: "14",
                // borderColor: "rgba(31,222,233,0.5000)",
                // borderWidth: 1,
                color: [
                  "#FF4D32FF",
                  "#540D8FFF",
                  "#242E91FF",
                  "#0A4DE8FF",
                  "#00E6A0FF",
                ],
              },
              b: {
                width: 25,
                align: "right",
                color: "#ffffff",
                fontSize: "14",
              },
              c: {
                color: "#ffffff",
                fontFamily: "UniDreamLED",
                fontSize: "20",
                width: 25,
                align: "center",
              },
              d: {
                borderColor: "rgba(31,222,233,0.5000)",
                borderWidth: 1,
              },
            },
          },
          itemWidth: 0, // 图例图形的宽度
          itemHeight: 0, // 图例图形的高度
          formatter: function (name) {
            let data = option.series[0].data;
            let tarValue = 0;
            for (let i = 0; i < data.length; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value;
              }
            }
            let arr = [
              "{a|" + name + "}" + "{c|" + tarValue + "}" + "{b|" + "人" + "}",
            ];

            return arr.join("\n");
          },
          borderColor: "rgba(31,222,233,0.5000)",
          borderWidth: 0,
          shadowColor: "red", //阴影颜色
          shadowOffsetX: [10, 10], //阴影水平方向上的偏移距离
          shadowOffsetY: 1, //阴影垂直方向上的偏移距离
          shadowBlur: 8,
          padding: [10, 15, 8, 10],
        },
        grid: {
          top: "10%",
          left: "10%",
          right: "10%",
          bottom: "10%",
          containLabel: true,
        },
        color: ["#C174F3", "#29C2A1FF", "#287DE3FF"],
        series: [
          {
            name: "Access From",
            type: "pie",
            // radius: ["100%", "0%"],
            radius: ["53%", "60%"],

            // center: ["25%", "50%"],
            silent: true, //禁用地图的hover事件
            avoidLabelOverlap: false,
            legendHoverLink: false,
            labelLayout: {
              hideOverlap: false,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.arr,
            label: {
              normal: {
                position: "inner",
                show: false,
                formatter: "{c}",
                color: "#fff",
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.renyuantu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
    align-items: center;
}

#renyuan {
  width: 50%;
  height: 100%;
}
.tuli {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
}
ul {
  list-style: none;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.kuang {
  width: 87%;
  height: 26px;
  // height: 16%;
  // box-shadow: inset 0px 0px 4px 2px rgba(56, 147, 192, 0.5);
  // border: 1px solid rgba(31, 222, 233, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 10px;
}
.fontOne {
  font-size: 20px;
  font-family: UniDreamLED;
  color: #ffffff;
}
.fontTwo {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.fontThree {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.jianju {
  margin: 0 0 0 8px;
}
.juzhong {
  width: 30px;
  text-align: center;
}
.flexs {
  display: flex;
  line-height: 36px;
}
ul {
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.kuang {
  // width: 87%;
  width: 95%;
  // height: 16px;
  height: 16%;
  // box-shadow: inset 0px 0px 4px 2px rgba(56, 147, 192, 0.5);
  // border: 1px solid rgba(31, 222, 233, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 4px 8px 4px 10px;
  .container{
    width: 100%;
    height: 100%;
    .up{
      width: 100%;
      height: 70%;
    }
    .down{
      width: 100%;
      height: 30%;
    }
  }

  .up{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left{
      display: flex;
      width: 60%;
      align-items: center;
      .point-star{
        background-color: aquamarine;
        width: 6px;
        height: 6px;
      }
      .text{
        margin-left: 10px;
        font-size: 12px;
        color: #c0c0c0;
      }
    }
    .right{
      width: 40%;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      color: #d3d3d3;
      letter-spacing: 2px;
      margin-right: 6%;
      letter-spacing: 2px;
      justify-content: flex-end;

    }
  }
  .down{
    display: flex;
    align-items: flex-end;
  }
}
.fontOne {
  font-size: 20px;
  font-family: UniDreamLED;
  color: #ffffff;
}
.fontTwo {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.fontThree {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.jianju {
  margin: 0 0 0 8px;
}
/deep/ .el-progress{
  width: 100%;
}
/deep/ .el-progress-bar__outer{
  background-color: #213046;
  height: 3px !important;
}
</style>
